import React from 'react';
import '../ContentPageLayout/ContentPageLayout.scss';
import './CookiesPolicy.scss';

const CookiesPolicy = () => (
  <div className="CookiesPolicy">
    <section className="ContentPageLayout-block">
      <p className="ContentPageLayout-text">
        En ¡Québueno! queremos ofrecerte la mejor experiencia de navegación posible. Para ello,
        utilizamos cookies que nos ayudan a analizar el tráfico, personalizar el contenido y mejorar
        nuestros servicios.
      </p>
      <h2 className="ContentPageLayout-subTitle">¿Qué son las cookies?</h2>
      <p className="ContentPageLayout-text">
        Una cookie es un pequeño archivo de texto que se descarga en tu ordenador o dispositivo
        móvil cuando visitas un sitio web. Las cookies permiten a la web, entre otras cosas,
        almacenar y recuperar información sobre tus hábitos de navegación, reconocerte en futuras
        visitas y ofrecerte una experiencia más personalizada.
      </p>
      <h2 className="ContentPageLayout-subTitle">¿Qué tipos de cookies utilizamos?</h2>
      <p className="ContentPageLayout-text">
        Utilizamos diferentes tipos de cookies con distintos fines:
      </p>
      <ul className="ContentPageLayout-list">
        <li className="ContentPageLayout-listItem">
          <strong className="ContentPageLayout-strong">Cookies esenciales:</strong> Son
          imprescindibles para el correcto funcionamiento de la web. Permiten que navegues por la
          página, accedas a áreas seguras y disfrutes de las funcionalidades básicas del sitio.
          Estas cookies no almacenan información personal identificable.
        </li>
        <li className="ContentPageLayout-listItem">
          <strong className="ContentPageLayout-strong">Cookies de análisis:</strong> Nos ayudan a
          entender cómo interactúas con la web, qué páginas visitas, cuánto tiempo permaneces en
          ellas, etc. Esta información nos permite mejorar el funcionamiento del sitio y ofrecerte
          una experiencia más satisfactoria. Estas cookies no te identifican personalmente, ya que
          los datos se recopilan de forma anónima.
        </li>
        <li className="ContentPageLayout-listItem">
          <strong className="ContentPageLayout-strong">Cookies de personalización:</strong> Nos
          permiten recordar tus preferencias (como el idioma o la región) y ofrecerte contenido y
          ofertas más relevantes para ti.
        </li>
        <li className="ContentPageLayout-listItem">
          <strong className="ContentPageLayout-strong">Cookies publicitarias:</strong> Se utilizan
          para mostrarte anuncios relevantes en función de tus intereses y hábitos de navegación.
          También nos ayudan a limitar el número de veces que ves un mismo anuncio y a medir la
          efectividad de las campañas publicitarias.
        </li>
      </ul>

      <h2 className="ContentPageLayout-subTitle">¿Quién utiliza las cookies?</h2>
      <ul className="ContentPageLayout-list">
        <li className="ContentPageLayout-listItem">
          <strong className="ContentPageLayout-strong">Cookies propias:</strong> Son las que
          instalamos nosotros mismos para el correcto funcionamiento de la web y para recopilar
          información anónima sobre su uso.
        </li>
        <li className="ContentPageLayout-listItem">
          <strong className="ContentPageLayout-strong">Cookies de terceros:</strong> Son las que
          instalan otros proveedores de servicios que utilizamos, como herramientas de análisis
          (Google Analytics), chat en vivo (Intercom) o plataformas publicitarias (Google Ads,
          AdRoll, Facebook, Bing Ads, Criteo, PuschCrew).
        </li>
      </ul>

      <h2 className="ContentPageLayout-subTitle">¿Cómo puedes gestionar las cookies?</h2>

      <p className="ContentPageLayout-text">
        Tienes el control total sobre las cookies. Puedes configurar tu navegador para aceptarlas,
        rechazarlas o eliminarlas. También puedes configurar tu navegador para que te avise cada vez
        que un sitio web intenta instalar una cookie.
      </p>
      <p className="ContentPageLayout-text">
        Ten en cuenta que si desactivas las cookies, es posible que algunas funcionalidades de la
        web no estén disponibles o no funcionen correctamente.
      </p>
      <p className="ContentPageLayout-text">
        Puedes encontrar más información sobre cómo gestionar las cookies en los siguientes enlaces:
      </p>
      <ul className="ContentPageLayout-list">
        <li className="ContentPageLayout-listItem">
          <a
            className="Link"
            href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
            target="_blank"
          >
            Firefox
          </a>
        </li>
        <li className="ContentPageLayout-listItem">
          <a
            className="Link"
            href="https://support.google.com/chrome/answer/95647?hl=es"
            target="_blank"
          >
            Chrome
          </a>
        </li>
        <li className="ContentPageLayout-listItem">
          <a
            className="Link"
            href="https://support.microsoft.com/en-us/products/windows"
            target="_blank"
          >
            Explorer
          </a>
        </li>
        <li className="ContentPageLayout-listItem">
          <a className="Link" href="https://support.apple.com/es-es/HT201265" target="_blank">
            Safari
          </a>
        </li>
        <li className="ContentPageLayout-listItem">
          <a
            className="Link"
            href="https://help.opera.com/en/latest/web-preferences/#cookies"
            target="_blank"
          >
            Opera
          </a>
        </li>
      </ul>

      <h2 className="ContentPageLayout-subTitle">Revocar el consentimiento</h2>
      <p className="ContentPageLayout-text">
        Puedes revocar tu consentimiento para el uso de cookies en cualquier momento a través de la
        configuración de tu navegador o a través de los siguientes enlaces:
      </p>

      <ul className="ContentPageLayout-list">
        <li className="ContentPageLayout-listItem">
          <a
            className="Link"
            href="https://tools.google.com/dlpage/gaoptout?hl=None"
            target="_blank"
          >
            Google Analytics
          </a>
        </li>
      </ul>

      <h2 className="ContentPageLayout-subTitle">Actualizaciones de la política de cookies</h2>

      <p className="ContentPageLayout-text">
        Nos reservamos el derecho a modificar esta Política de Cookies en cualquier momento para
        adaptarla a las nuevas exigencias legislativas o a las instrucciones de la Agencia Española
        de Protección de Datos. Te informaremos de cualquier cambio significativo a través de un
        aviso en la web o por correo electrónico si eres un usuario registrado.
      </p>

      <h2 className="ContentPageLayout-subTitle">Más información</h2>

      <p className="ContentPageLayout-text">
        Si tienes alguna pregunta sobre nuestra Política de Cookies, puedes contactarnos en{' '}
        <a className="Link" title="info@quebueno.es" href="mailto:info@quebueno.es">
          info@quebueno.es
        </a>
      </p>

      <h2 className="ContentPageLayout-subTitle">Enlaces a terceros</h2>

      <p className="ContentPageLayout-text">
        No nos hacemos responsables del contenido y veracidad de los enlaces a páginas web de
        terceros incluidos en esta Política de Cookies.
      </p>
    </section>
  </div>
);

export default CookiesPolicy;
