import React from 'react';
import ContentPageLayout from '../components/ContentPageLayout';
import CookiesPolicy from '../components/CookiesPolicy';

const Page = () => (
  <ContentPageLayout title="Política de Cookies de ¡Québueno!">
    <CookiesPolicy />
  </ContentPageLayout>
);

export default Page;
